import isEmpty from "lodash/isEmpty";

const validateHistory = (values, setErrors) => {
  let errors = {};

  const presence = ["travelled_last_14_days", "has_covid_exposure"];

  if (values.travelled_last_14_days === "yes") {
    presence.push(
      "travel_mode",
      "travel_number",
      "port_of_exit",
      "date_of_departure",
      "date_of_arrival_to_ph"
    );
  }

  if (values.has_covid_exposure === "yes") {
    presence.push("has_covid_exposure_remarks");
  }

  presence.forEach((item) => {
    if (!values[item]) {
      errors[item] = "Required field";
    }
  });

  setErrors(errors);

  /* Valid if errors is empty - will return true */
  return isEmpty(errors);
};

export default validateHistory;
