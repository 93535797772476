import React from "react";
import "./App.less";
import PatientRegistration from "pages/PatientRegistration";

const App = () => (
  <div className="App">
    <PatientRegistration />
  </div>
);

export default App;
