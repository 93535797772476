import isEmpty from "lodash/isEmpty";

const validateClinical = (values, setErrors) => {
  let errors = {};

  let presence = [
    "symptoms",
    "comorbidities",
    "has_other_illness",
    "has_xray",
    "is_pregnant",
    "cxr_result",
  ];

  if (values.symptoms?.includes("fever")) {
    presence.push("fever_temp");
  }

  if (values.has_other_illness === "yes") {
    presence.push("has_other_illness_remarks");
  }

  if (values.has_xray === "yes") {
    presence.push("has_xray_date");
  }

  if (values.fever_temp) {
    if (parseFloat(values.fever_temp) > 42) {
      errors.fever_temp = "Must be less than 42";
    } else if (parseFloat(values.fever_temp) < 12) {
      errors.fever_temp = "Must be greater than 12";
    }
  }

  presence.forEach((item) => {
    if (!values[item]) {
      errors[item] = "Required field";
    }
  });

  setErrors(errors);

  /* Valid if errors is empty - will return true */
  return isEmpty(errors);
};

export default validateClinical;
