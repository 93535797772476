import { useState } from "react";
import { Form, Steps, Button, message, Card } from "antd";
import axios from "axios";

import PatientInfo from "./PatientInfo";
import Residence from "./Residence";
import History from "./History";
import Clinical from "./Clinical";
import validatePatientDetails from "./validators/validatePatientDetails";
import validateResidence from "./validators/validateResidence";
import validateHistory from "./validators/validateHistory";
import validateClinical from "./validators/validateClinical";

const { Step } = Steps;

const Wizard = ({
  values,
  errors,
  handleChange,
  setErrors,
  setReferenceId,
}) => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = [
    {
      title: "Patient Details",
      content: (
        <PatientInfo
          values={values}
          errors={errors}
          handleChange={handleChange}
        />
      ),
      validator: validatePatientDetails,
    },
    {
      title: "Residence",
      content: (
        <Residence
          values={values}
          errors={errors}
          handleChange={handleChange}
        />
      ),
      validator: validateResidence,
    },
    {
      title: "History",
      content: (
        <History values={values} errors={errors} handleChange={handleChange} />
      ),
      validator: validateHistory,
    },
    {
      title: "Clinical Information",
      content: (
        <Clinical values={values} errors={errors} handleChange={handleChange} />
      ),
      validator: validateClinical,
    },
  ];

  const handleSubmit = () => {
    setIsSubmitting(true);
    axios
      .post(
        "https://truhealth.herokuapp.com/api/v1/lab_tests/registrations",
        values
      )
      .then(function (response) {
        const {
          data: { reference_id },
        } = response;
        setReferenceId(reference_id);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  const next = () => {
    const valid = steps[current].validator(values, setErrors);
    if (valid) {
      if (current === steps.length - 1) {
        handleSubmit();
      } else {
        setCurrent(current + 1);
      }
    } else {
      message.error("Please fill up all required fields.");
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <Form formLayout="vertical" form={form} layout="vertical" size="large">
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Card style={{ marginTop: "2rem" }}>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action" style={{ marginTop: "1rem" }}>
          {current > 0 && (
            <Button
              size="large"
              shape="round"
              style={{ margin: "0 8px" }}
              onClick={() => prev()}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Previous
            </Button>
          )}
          <Button
            size="large"
            shape="round"
            type="primary"
            onClick={() => next()}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {current === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </div>
      </Card>
    </Form>
  );
};

export default Wizard;
