import { useState, useCallback } from "react";
import Container from "components/Container";
import Wizard from "./Wizard";
import { Card, Typography, Button } from "antd";
const { Text } = Typography;

const PatientRegistration = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [referenceId, setReferenceId] = useState(undefined);

  const handleChange = useCallback(
    (field, value) => {
      setValues({ ...values, [field]: value });
    },
    [setValues, values]
  );

  const onPrint = () => {
    window.print();
  };

  return (
    <Container>
      {referenceId ? (
        <Card style={{ marginTop: "2rem", textAlign: "center" }}>
          <Text type="secondary">
            {" "}
            Your Truhealth E-Registration Reference #
          </Text>
          <h1 type="success" style={{ fontSize: "4rem", marginBottom: 0 }}>
            {referenceId}
          </h1>
          <img
            alt="referenceId"
            src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${referenceId}`}
          />
          <br />
          <br />
          <Text type="primary">
            {`${values.last || ""}, ${values?.first || ""} ${
              values?.middle || ""
            }`.toUpperCase()}
          </Text>
          <br />
          <br />
          <Text type="secondary">
            Save this Reference # or QR code to your device and present it to a
            Truhealth Diagnostics Centre Branch.
          </Text>
          <br />
          <br />
          <Button type="secondary" shape="round" size="large" onClick={onPrint}>
            Print
          </Button>
        </Card>
      ) : (
        <Wizard
          values={values}
          errors={errors}
          handleChange={handleChange}
          setErrors={setErrors}
          setReferenceId={setReferenceId}
        />
      )}
    </Container>
  );
};

export default PatientRegistration;
