import { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, Collapse } from "antd";
import {
  regions,
  provinces,
  cities,
  barangays,
} from "select-philippines-address";
import countryList from "country-list";

const Residence = ({ values, handleChange, errors }) => {
  const [regionsOpt, setRegionsOpt] = useState([]);
  const [provinceOpt, setProvinceOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [barangayOpt, setBarangayOpt] = useState([]);

  /* Populate Region */
  useEffect(() => {
    regions().then((list) => {
      setRegionsOpt(list);
    });
  }, []);

  /* Populate Province */
  useEffect(() => {
    if (values.region) {
      const region = regionsOpt.find(
        (opt) => opt.region_name === values.region
      );
      region &&
        provinces(region.region_code).then((list) => {
          setProvinceOpt(list);
        });
    }
  }, [values.region, regionsOpt]);

  /* Populate Cities */
  useEffect(() => {
    if (values.province) {
      const province = provinceOpt.find(
        (opt) => opt.province_name === values.province
      );
      province &&
        cities(province.province_code).then((list) => {
          setCityOpt(list);
        });
    }
  }, [values.province, provinceOpt]);

  /* Populate Barangays */
  useEffect(() => {
    if (values.city) {
      const city = cityOpt.find((opt) => opt.city_name === values.city);
      city &&
        barangays(city.city_code).then((list) => {
          setBarangayOpt(list);
        });
    }
  }, [values.city, cityOpt]);

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Region"
            name="region"
            validateStatus={errors.region && "error"}
            help={errors.region}
            required
          >
            <Select
              showSearch
              placeholder="Select Region"
              required
              onChange={(value) => handleChange("region", value)}
            >
              {regionsOpt.map((opt) => (
                <Select.Option value={opt.region_name} key={opt.region_name}>
                  {opt.region_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Province"
            name="province"
            validateStatus={errors.province && "error"}
            help={errors.province}
            required
          >
            <Select
              showSearch
              placeholder="Select Province"
              required
              onChange={(value) => handleChange("province", value)}
            >
              {provinceOpt.map((opt) => (
                <Select.Option
                  value={opt.province_name}
                  key={opt.province_name}
                >
                  {opt.province_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Municipality / City"
            name="city"
            validateStatus={errors.city && "error"}
            help={errors.city}
            required
          >
            <Select
              showSearch
              placeholder="Select City"
              required
              onChange={(value) => handleChange("city", value)}
            >
              {cityOpt.map((opt) => (
                <Select.Option value={opt.city_name} key={opt.city_name}>
                  {opt.city_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Barangay"
            name="barangay"
            validateStatus={errors.barangay && "error"}
            help={errors.barangay}
            required
          >
            <Select
              showSearch
              placeholder="Select Barangay"
              required
              onChange={(value) => handleChange("barangay", value)}
            >
              {barangayOpt.map((opt) => (
                <Select.Option value={opt.brgy_name} key={opt.brgy_name}>
                  {opt.brgy_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={4} xs={24}>
          <Form.Item
            name="house_no"
            label="House #"
            validateStatus={errors.house_no && "error"}
            help={errors.house_no}
            required
          >
            <Input
              onChange={(e) => handleChange("house_no", e.target.value)}
              value={values.house_no}
            />
          </Form.Item>
        </Col>
        <Col sm={18} xs={24}>
          <Form.Item
            name="street"
            label="Street"
            validateStatus={errors.street && "error"}
            help={errors.street}
            required
          >
            <Input
              onChange={(e) => handleChange("street", e.target.value)}
              value={values.street}
            />
          </Form.Item>
        </Col>
      </Row>
      <Collapse>
        <Collapse.Panel header="Are you an Overseas Filipino Worker?" key="1">
          <Row gutter={[16, 0]}>
            <Col sm={8} xs={24}>
              <Form.Item
                name="oea_employer_name"
                label="Employer name"
                validateStatus={errors.oea_employer_name && "error"}
                help={errors.oea_employer_name}
              >
                <Input
                  onChange={(e) =>
                    handleChange("oea_employer_name", e.target.value)
                  }
                  value={values.oea_employer_name}
                />
              </Form.Item>
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                name="oea_occupation"
                label="Occupation"
                validateStatus={errors.oea_occupation && "error"}
                help={errors.oea_occupation}
              >
                <Input
                  onChange={(e) =>
                    handleChange("oea_occupation", e.target.value)
                  }
                  value={values.oea_occupation}
                />
              </Form.Item>
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                name="oea_place_of_work"
                label="Place of work"
                validateStatus={errors.oea_place_of_work && "error"}
                help={errors.oea_place_of_work}
              >
                <Input
                  onChange={(e) =>
                    handleChange("oea_place_of_work", e.target.value)
                  }
                  value={values.oea_place_of_work}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col sm={4} xs={24}>
              <Form.Item
                name="oea_house_no"
                label="House #"
                validateStatus={errors.oea_house_no && "error"}
                help={errors.oea_house_no}
              >
                <Input
                  onChange={(e) => handleChange("oea_house_no", e.target.value)}
                  value={values.oea_house_no}
                />
              </Form.Item>
            </Col>
            <Col sm={5} xs={24}>
              <Form.Item
                name="oea_street"
                label="Street"
                validateStatus={errors.oea_street && "error"}
                help={errors.oea_street}
              >
                <Input
                  onChange={(e) => handleChange("oea_street", e.target.value)}
                  value={values.oea_street}
                />
              </Form.Item>
            </Col>
            <Col sm={5} xs={24}>
              <Form.Item
                name="oea_province"
                label="State / Province"
                validateStatus={errors.oea_province && "error"}
                help={errors.oea_province}
              >
                <Input
                  onChange={(e) => handleChange("oea_province", e.target.value)}
                  value={values.oea_province}
                />
              </Form.Item>
            </Col>
            <Col sm={5} xs={24}>
              <Form.Item
                name="oea_city"
                label="City / Municipality"
                validateStatus={errors.oea_city && "error"}
                help={errors.oea_city}
              >
                <Input
                  onChange={(e) => handleChange("oea_city", e.target.value)}
                  value={values.oea_city}
                />
              </Form.Item>
            </Col>
            <Col sm={5} xs={24}>
              <Form.Item
                name="oea_country"
                label="City / Municipality"
                validateStatus={errors.oea_city && "error"}
                help={errors.oea_city}
              >
                <Select
                  showSearch
                  placeholder="Select Country"
                  required
                  onChange={(value) => handleChange("oea_country", value)}
                >
                  {countryList.getNames().map((item) => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col sm={8} xs={24}>
              <Form.Item
                name="passport_no"
                label="Passport #"
                validateStatus={errors.passport_no && "error"}
                help={errors.passport_no}
              >
                <Input
                  onChange={(e) => handleChange("passport_no", e.target.value)}
                  value={values.passport_no}
                />
              </Form.Item>
            </Col>
            {/* <Col sm={5} xs={24}>
              <Form.Item
                name="passport_file"
                label="Passport file"
                validateStatus={errors.passport_file && "error"}
                help={errors.passport_file}
              ></Form.Item>
            </Col> */}
          </Row>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default Residence;
