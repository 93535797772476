import { Form, Input, Row, Col, Select, DatePicker } from "antd";

const PatientInfo = ({ values, handleChange, errors }) => {
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col sm={8} xs={24}>
          <Form.Item
            label="Last name"
            name="last"
            validateStatus={errors.last && "error"}
            help={errors.last}
            required
          >
            <Input
              placeholder="Dela Cruz"
              onChange={(e) => handleChange("last", e.target.value)}
              value={values.last}
            />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            label="First name"
            name="first"
            validateStatus={errors.first && "error"}
            help={errors.first}
            required
          >
            <Input
              placeholder="Juan"
              onChange={(e) => handleChange("first", e.target.value)}
              value={values.first}
            />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            label="Middle name"
            name="middle"
            tooltip="Optional"
            validateStatus={errors.middle && "error"}
            help={errors.middle}
          >
            <Input
              placeholder="Garcia"
              onChange={(e) => handleChange("middle", e.target.value)}
              value={values.middle}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={8} xs={24}>
          <Form.Item
            name="email"
            label="Email"
            validateStatus={errors.email && "error"}
            help={errors.email}
            required
          >
            <Input
              type="email"
              placeholder="Email"
              onChange={(e) => handleChange("email", e.target.value)}
              value={values.email}
            />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name="mobile"
            label="Mobile #"
            validateStatus={errors.mobile && "error"}
            help={errors.mobile}
            required
          >
            <Input
              onChange={(e) => handleChange("mobile", e.target.value)}
              value={values.mobile}
            />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name="telephone"
            label="Home Phone #"
            validateStatus={errors.telephone && "error"}
            help={errors.telephone}
          >
            <Input
              onChange={(e) => handleChange("telephone", e.target.value)}
              value={values.telephone}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={8} xs={24}>
          <Form.Item
            name="gender"
            label="Gender"
            validateStatus={errors.gender && "error"}
            help={errors.gender}
            rules={[{ required: true, message: "Please select gender" }]}
          >
            <Select
              showSearch
              placeholder="Selct your gender"
              required
              onChange={(value) => handleChange("gender", value)}
            >
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name="civil_status"
            label="Civil status"
            validateStatus={errors.civil_status && "error"}
            help={errors.civil_status}
            rules={[{ required: true, message: "Please select civil status" }]}
          >
            <Select
              showSearch
              placeholder="Selct civil status"
              required
              onChange={(value) => handleChange("civil_status", value)}
            >
              <Select.Option value="single">Single</Select.Option>
              <Select.Option value="married">Married</Select.Option>
              <Select.Option value="widowed">Widowed</Select.Option>
              <Select.Option value="separated">Separated</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name="birthdate"
            label="Birth date"
            validateStatus={errors.birthdate && "error"}
            help={errors.birthdate}
            rules={[{ required: true, message: "Set Birth date" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(date, dateString) =>
                handleChange("birthdate", dateString)
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={8} xs={24}>
          <Form.Item
            name="nationality"
            label="Nationality"
            validateStatus={errors.nationality && "error"}
            help={errors.nationality}
            required
          >
            <Input
              placeholder="Filipino"
              onChange={(e) => handleChange("nationality", e.target.value)}
              value={values.nationality}
            />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name="occupation"
            label="Occupation"
            validateStatus={errors.occupation && "error"}
            help={errors.occupation}
            required
          >
            <Input
              onChange={(e) => handleChange("occupation", e.target.value)}
              value={values.occupation}
            />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            validateStatus={errors.philhealth_member && "error"}
            help={errors.philhealth_member}
            rules={[
              {
                len: 12,
                message: "Philhealth number must be exactly 12 characters ",
              },
            ]}
            name="philhealth_member"
            label="Philhealth number"
            tooltip="Optional"
          >
            <Input
              onChange={(e) =>
                handleChange("philhealth_member", e.target.value)
              }
              value={values.philhealth_member}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PatientInfo;
