import { Form, Input, Row, Col, Radio, DatePicker } from "antd";

const History = ({ values, handleChange, errors }) => {
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col sm={10} xs={24}>
          <Form.Item
            label="History of travel/visit/work in other countries within last 14 days"
            name="travelled_last_14_days"
            validateStatus={errors.travelled_last_14_days && "error"}
            help={errors.travelled_last_14_days}
            required
          >
            <Radio.Group
              onChange={(e) =>
                handleChange("travelled_last_14_days", e.target.value)
              }
              value={values.travelled_last_14_days}
            >
              <Radio value="no">NO</Radio>
              <Radio value="yes">YES</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {values.travelled_last_14_days === "yes" && (
        <Row gutter={[16, 0]}>
          <Col sm={5} xs={24}>
            <Form.Item
              label="Port of exit"
              name="port_of_exit"
              validateStatus={errors.port_of_exit && "error"}
              help={errors.port_of_exit}
            >
              <Input
                onChange={(e) => handleChange("port_of_exit", e.target.value)}
                value={values.port_of_exit}
              />
            </Form.Item>
          </Col>
          <Col sm={5} xs={24}>
            <Form.Item
              label="Travel mode"
              name="travel_mode"
              validateStatus={errors.travel_mode && "error"}
              help={errors.travel_mode}
            >
              <Input
                onChange={(e) => handleChange("travel_mode", e.target.value)}
                value={values.travel_mode}
              />
            </Form.Item>
          </Col>
          <Col sm={5} xs={24}>
            <Form.Item
              label="Travel #"
              name="travel_number"
              validateStatus={errors.travel_number && "error"}
              help={errors.travel_number}
            >
              <Input
                onChange={(e) => handleChange("travel_number", e.target.value)}
                value={values.travel_number}
              />
            </Form.Item>
          </Col>
          <Col sm={4} xs={24}>
            <Form.Item
              label="Date of departure"
              name="date_of_departure"
              validateStatus={errors.date_of_departure && "error"}
              help={errors.date_of_departure}
            >
              <DatePicker
                style={{ width: "100%" }}
                onChange={(date, dateString) =>
                  handleChange("date_of_departure", dateString)
                }
              />
            </Form.Item>
          </Col>
          <Col sm={4} xs={24}>
            <Form.Item
              label="Date of arrival to PH"
              name="date_of_arrival_to_ph"
              validateStatus={errors.date_of_arrival_to_ph && "error"}
              help={errors.date_of_arrival_to_ph}
            >
              <DatePicker
                style={{ width: "100%" }}
                onChange={(date, dateString) =>
                  handleChange("date_of_arrival_to_ph", dateString)
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <hr />
      <Row gutter={[16, 0]}>
        <Col sm={8} xs={24}>
          <Form.Item
            label="History of Exposure to Known CoViD-19 Case"
            name="has_covid_exposure"
            validateStatus={errors.has_covid_exposure && "error"}
            help={errors.has_covid_exposure}
            required
          >
            <Radio.Group
              onChange={(e) =>
                handleChange("has_covid_exposure", e.target.value)
              }
              value={values.has_covid_exposure}
            >
              <Radio value="no">NO</Radio>
              <Radio value="yes">YES</Radio>
              <Radio value="unkown">UNKNOWN</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {values.has_covid_exposure === "yes" && (
          <Col sm={8} xs={24}>
            <Form.Item
              label="Date and Contact with Known CoViD-19 Case"
              name="has_covid_exposure_remarks"
              validateStatus={errors.has_covid_exposure_remarks && "error"}
              help={errors.has_covid_exposure_remarks}
            >
              <Input
                onChange={(e) =>
                  handleChange("has_covid_exposure_remarks", e.target.value)
                }
                value={values.has_covid_exposure_remarks}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default History;
