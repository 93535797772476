import isEmpty from "lodash/isEmpty";

const validateResidence = (values, setErrors) => {
  let errors = {};

  const presence = [
    "region",
    "province",
    "city",
    "barangay",
    "house_no",
    "street",
  ];

  presence.forEach((item) => {
    if (!values[item]) {
      errors[item] = "Required field";
    }
  });

  setErrors(errors);

  /* Valid if errors is empty - will return true */
  return isEmpty(errors);
};

export default validateResidence;
