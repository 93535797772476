import styled from "styled-components";
import { Layout } from "antd";

const { Header, Content, Footer } = Layout;

const SContent = styled(Content)`
  @media only screen and (min-width: 768px) {
    padding: 0 50px;
  }
`;

const Container = ({ children }) => {
  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <a href="https://truhealthdiagnostics.com/">
          <img
            src="https://truhealth-web.s3.us-east-2.amazonaws.com/static/assets/truhealth_logo.jpg"
            style={{ textAlign: "center", width: 100 }}
            alt="truhealth"
          />
        </a>
        <h3>E-REGISTRATION</h3>
      </Header>
      <SContent>
        <div className="site-layout-content" style={{ padding: "5vh" }}>
          {children}
        </div>
      </SContent>
      <Footer style={{ textAlign: "center" }}>
        Truhealth Diagnostic Centre Corp ©2021
      </Footer>
    </Layout>
  );
};

export default Container;
