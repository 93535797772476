import isEmpty from "lodash/isEmpty";
import EmailValidator from "email-validator";

const validatePatientDetails = (values, setErrors) => {
  let errors = {};

  const presence = [
    "last",
    "first",
    "gender",
    "civil_status",
    "birthdate",
    "nationality",
    "occupation",
    "email",
    "mobile",
  ];

  presence.forEach((item) => {
    if (!values[item]) {
      errors[item] = "Required field";
    }
  });

  if (values.philhealth_member) {
    if (values.philhealth_member.length !== 12) {
      errors.philhealth_member =
        "Philhealth number must be exactly 12 characters";
    }
  }

  if (values.email && !EmailValidator.validate(values.email)) {
    errors.email = "Email is invalid";
  }

  setErrors(errors);

  /* Valid if errors is empty - will return true */
  return isEmpty(errors);
};

export default validatePatientDetails;
