import { Form, Input, Row, Col, Radio, DatePicker, Checkbox } from "antd";

const comorbidities = [
  { value: "none", label: "None" },
  { value: "hypertension", label: "Hypertension" },
  { value: "diabetes", label: "Diabetes" },
  { value: "heart_disease", label: "Heart Disease" },
  { value: "lung_disease", label: "Lung Disease" },
  { value: "gastrointestinal", label: "Gastrointestinal" },
  { value: "genito_urinary", label: "Genito Urinary" },
  { value: "neurological_disease", label: "Neurological Disease" },
  { value: "cancer", label: "Cancer" },
];

const symptoms = [
  { value: "asymptomatic", label: "Asymptomatic" },
  { value: "fever", label: "Fever" },
  { value: "cough", label: "Cough" },
  { value: "general_weakness", label: "General Weakness" },
  { value: "fatigue", label: "Fatigue" },
  { value: "headache", label: "Headache" },
  { value: "myalgia", label: "Myalgia" },
  { value: "sore_throat", label: "Sore Throat" },
  { value: "coryza", label: "Coryza" },
  { value: "dyspnea", label: "Dyspnes" },
  { value: "anorexia", label: "Anorexia" },
  { value: "nausea", label: "Nausea" },
  { value: "vomiting", label: "Vomiting" },
  { value: "diarrhea", label: "Diarrhea" },
  { value: "altered_mental_status", label: "Altered mental status" },
  { value: "anosmia", label: "Anosmia" },
  { value: "ageusia", label: "Ageusia" },
  { value: "shortness_of_breathing", label: "Shortness of breathing" },
];

const Clinical = ({ values, handleChange, errors }) => {
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Symptoms"
            name="symptoms"
            validateStatus={errors.symptoms && "error"}
            help={errors.symptoms}
            required
          >
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={(e) => handleChange("symptoms", e)}
            >
              <Row>
                {symptoms.map((item) => (
                  <Col sm={16} xs={24} key={item.value}>
                    <Checkbox value={item.value}>{item.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Row gutter={[16, 0]}>
            <Col xs={24}>
              <Form.Item
                label="Other symptoms, please specify"
                name="other_symptoms"
                validateStatus={errors.other_symptoms && "error"}
                help={errors.other_symptoms}
              >
                <Input
                  onChange={(e) =>
                    handleChange("other_symptoms", e.target.value)
                  }
                  value={values.other_symptoms}
                />
              </Form.Item>
            </Col>
          </Row>
          {values.symptoms?.includes("fever") && (
            <Row gutter={[16, 0]}>
              <Col xs={24}>
                <Form.Item
                  label="Fever Temperature"
                  name="fever_temp"
                  validateStatus={errors.fever_temp && "error"}
                  help={errors.fever_temp}
                >
                  <Input
                    type="number"
                    placeholder="Temperature in Celsius (e.g. 36.2)"
                    onChange={(e) => handleChange("fever_temp", e.target.value)}
                    value={values.fever_temp}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Comorbidities"
            name="comorbidities"
            validateStatus={errors.comorbidities && "error"}
            help={errors.comorbidities}
            required
          >
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={(e) => handleChange("comorbidities", e)}
            >
              {comorbidities.map((item) => (
                <Row key={item.value}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Row>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            label="Other comorbidities, please specify"
            name="other_comorbidities"
            validateStatus={errors.other_comorbidities && "error"}
            help={errors.other_comorbidities}
          >
            <Input
              onChange={(e) =>
                handleChange("other_comorbidities", e.target.value)
              }
              value={values.other_comorbidities}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Is there any history of other illness?"
            name="has_other_illness"
            validateStatus={errors.has_other_illness && "error"}
            help={errors.has_other_illness}
            required
          >
            <Radio.Group
              onChange={(e) =>
                handleChange("has_other_illness", e.target.value)
              }
              value={values.has_other_illness}
            >
              <Radio value="no">NO</Radio>
              <Radio value="yes">YES</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {values.has_other_illness === "yes" && (
          <Col sm={8} xs={24}>
            <Form.Item
              label="Specify other illness:"
              name="has_other_illness_remarks"
              validateStatus={errors.has_other_illness_remarks && "error"}
              help={errors.has_other_illness_remarks}
            >
              <Input
                onChange={(e) =>
                  handleChange("has_other_illness_remarks", e.target.value)
                }
                value={values.has_other_illness_remarks}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Chest X-Ray done?"
            name="has_xray"
            validateStatus={errors.has_xray && "error"}
            help={errors.has_xray}
            required
          >
            <Radio.Group
              onChange={(e) => handleChange("has_xray", e.target.value)}
              value={values.has_xray}
            >
              <Radio value="no">NO</Radio>
              <Radio value="yes">YES</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {values.has_xray === "yes" && (
          <Col sm={8} xs={24}>
            <Form.Item
              label="Date of X-Ray:"
              name="has_xray_date"
              validateStatus={errors.has_xray_date && "error"}
              help={errors.has_xray_date}
            >
              <DatePicker
                style={{ width: "100%" }}
                onChange={(date, dateString) =>
                  handleChange("has_xray_date", dateString)
                }
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Are you pregnant?"
            name="is_pregnant"
            validateStatus={errors.is_pregnant && "error"}
            help={errors.is_pregnant}
            required
          >
            <Radio.Group
              onChange={(e) => handleChange("is_pregnant", e.target.value)}
              value={values.is_pregnant}
            >
              <Radio value="no">NO</Radio>
              <Radio value="yes">YES</Radio>
              <Radio value="unknown">UNKNOWN</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col sm={6} xs={24}>
          <Form.Item
            label="Do you have CXR Results Pneumonia?"
            name="cxr_result"
            validateStatus={errors.cxr_result && "error"}
            help={errors.cxr_result}
            required
          >
            <Radio.Group
              onChange={(e) => handleChange("cxr_result", e.target.value)}
              value={values.cxr_result}
            >
              <Radio value="no">NO</Radio>
              <Radio value="yes">YES</Radio>
              <Radio value="pending">PENDING</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            label="Other radiologic findings"
            name="other_radiologic_findings"
            validateStatus={errors.other_radiologic_findings && "error"}
            help={errors.other_radiologic_findings}
            required
          >
            <Input
              onChange={(e) =>
                handleChange("has_other_illness_remarks", e.target.value)
              }
              value={values.has_other_illness_remarks}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Clinical;
